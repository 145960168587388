import React from 'react';
import { useEffect } from 'react';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container,Row,Col } from "react-bootstrap";
import {Helmet} from "react-helmet";
import Videopop from '../Popups/Videopop';
import Videopop01 from '../Popups/videopop01';
import Videopop02 from '../Popups/videopop02';
import Videopop03 from '../Popups/videopop03';

const BanuSpeaks = () => {

  useEffect(() => {
    document.body.classList.add('BanuspeaksPage')
    return () => {
        document.body.classList.remove('BanuspeaksPage')
    }
    }, [])
    const [videoShow, setModalShow] = React.useState(false);
    const [videoShow01, setModalShow01] = React.useState(false);
    const [videoShow02, setModalShow02] = React.useState(false);
    const [videoShow03, setModalShow03] = React.useState(false);
    
  return (
    <div className='AboutusCont'>
    <Helmet>
        <title>Income Tax and Allied Economic Laws | Banusekar.in</title>
        <meta name="theme-color" content="#000000" />
        //<meta name="title" content="Income Tax and Allied Economic Laws | Banusekar.in"/>		
        <meta name="description" content="Find more about income tax and allied economic laws. We have experience in taxation laws & regulatory advice."/>
        <meta name="keywords" content="Income Tax and Allied Economic Laws, Economic Laws, taxation laws, financial advice in tax."/>		
     </Helmet>
        <InnerBannerSec title="Banu Speaks"/>
        <section className='SpeaksIntroSec'>
            <Container>
              <div className='ComSecrow'>
                <div className='BSintrocont'>
                  <div className='ComHeadTitles' data-aos="fade-left" data-aos-duration="1000" >
                      {/* <h5>introduction</h5> */}
                      <h2>Exploring the Nitty Gritties of <br/>Tax and Other Allied Economic Laws</h2> 
                      <p>Engage in insightful conversations through these videos</p>
                  </div>
                </div>
              </div>
            </Container>
            <div className='Speaksec'>
            <Container>
            <Row>
                    <Col>
                        <div className='hneItems' >
                            <div className='hneItemImg speakvid' onClick={() => setModalShow01(true)}>
                                <img src={require("../../assets/images/Banu-video-img01.jpg")} alt="News Events"/>
                            </div>
                            <div className='hneItemCont' data-aos="fade-up" data-aos-duration="1000">
                                <h3>Scope of section 263 in Limited Scrutiny cases</h3>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='hneItems' >
                            <div className='hneItemImg speakvid' onClick={() => setModalShow02(true)}>
                                <img src={require("../../assets/images/Banu-video-img02.jpg")} alt="News Events"/>
                            </div>
                            <div className='hneItemCont' data-aos="fade-up" data-aos-duration="1000">
                                <h3>Section 10(23C)(vi) – Effect of New Noble Educational Society – Prospective or Retrospective</h3>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='hneItems' >
                            <div className='hneItemImg speakvid' onClick={() => setModalShow03(true)}>
                                <img src={require("../../assets/images/Banu-video-img03.jpg")} alt="News Events"/>
                            </div>
                            <div className='hneItemCont' data-aos="fade-up" data-aos-duration="1000">
                                <h3>Expenditure incurred in Illegal Business – Allowability</h3>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='hneItems' >
                            <div className='hneItemImg speakvid' onClick={() => setModalShow(true)}>
                                <img src={require("../../assets/images/Banu-video-img04.jpg")} alt="News Events"/>
                            </div>
                            <div className='hneItemCont' data-aos="fade-up" data-aos-duration="1000">
                                <h3>Scientific research expenditure – Disallowance by AO whether permissible</h3>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
           
        </section>
        <Videopop show={videoShow} onHide={() => setModalShow(false)}/>
        <Videopop01 show={videoShow01} onHide={() => setModalShow01(false)}/>
        <Videopop02 show={videoShow02} onHide={() => setModalShow02(false)}/>
        <Videopop03 show={videoShow03} onHide={() => setModalShow03(false)}/>



       

        {/* <NewEventSec/> */}
    </div>
  )
}

export default BanuSpeaks