import React from 'react';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Container  } from 'react-bootstrap';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube , faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import {Helmet} from "react-helmet";
import ContactusForm from "./ContactusForm";

const Contactus = () => {
    useEffect(() => {
    document.body.classList.add('ContactusPage')
    return () => {
        document.body.classList.remove('ContactusPage')
    }
    }, [])


  return (
    <div className='ContusCont'>
    <Helmet>
      <title>Banusekar - Contact Us</title>
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Banusekar - Conatct Us"/>
    </Helmet>
        <InnerBannerSec title="Contact Us" menutitle="Contact Us"/>
        <section className='ContusIntroSec'>
            <Container>
              <div className='Cotfrmcontn'>
                <div className='ContfLCol'>
                    <div className='Contfimg'><img src={require("../../assets/images/contusfrombsimg.jpg")} alt="Banusekar"  data-aos="fade" data-aos-duration="1000"/></div>
                    <div className='Contfaddrs'>
                        <h2>The Law Chambers of Banusekar</h2>
                        <p>NO 37, 1st Floor, Alagiri Nagar 2nd St,<br/> Vadapalani, Chennai, <br/>Tamil Nadu 600026</p>
                        <h5>Working hours</h5>
                        <p>Monday to Saturday : 10AM - 6PM<br/> Closed :  Sundays & Public Holidays</p>
                    </div>
                </div>
                <div className='ContfRCol'>
                  <div className='Contfrmdiv' data-aos="fade-left" data-aos-duration="1000" >
                      <h3>Get in touch</h3> 
                      <ContactusForm/>
                      <div className='ContusPESMList'>
                        <div className='ContusPESM ContPhone'>
                            <h5>Phone</h5>
                            <h4><Link to="tel:+919363997647">93639 97647</Link></h4>
                        </div>
                        <div className='ContusPESM ContEmail'>
                            <h5>Email Now</h5>
                            <h4><Link to="mailto:info@banusekar.in">info@banusekar.in</Link></h4>
                        </div>
                        <div className='ContusPESM ContSmedia'>
                            <h5>Follow Us</h5>
                            <ul className='smedialink'>
                                <li><Link to="https://www.linkedin.com/in/banusekar-thyagarajan-7495b917/?originalSubdomain=in/"><FontAwesomeIcon icon={faLinkedinIn} /></Link></li>
                                <li><Link to="https://www.youtube.com/@BanuSpeaks"><FontAwesomeIcon icon={faYoutube} /></Link></li>
                            </ul>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </Container>
        </section>
    </div>
  )
}

export default Contactus