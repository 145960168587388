import React from 'react';
import { useEffect } from 'react';
import NewEventSec from '../CommComponent/NewEventSec';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";

const BlackMoney = () => { 
    useEffect(() => {
        document.body.classList.add('ServicesPage')
        return () => {
            document.body.classList.remove('ServicesPage')
        }
    }, [])
  return (
    <>
        <Helmet>
        <title>Transfer Pricing Audit in Income Tax | Banusekar.in</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Transfer pricing regulations were introduced in 2001 in India to overcome the Indian tax. The transfer price is the exact price charged in a transaction. The law of transfer pricing applies to domestic and international transactions."/>
		<meta name="keywords" content="Transfer pricing audit, Transfer pricing audit example, transfer pricing audit checklist, transfer pricing audit applicability, threshold limit for transfer pricing audit in india, transfer pricing audit turnover limit, transfer pricing audit report, Transfer pricing audit format."/>
		 
		 </Helmet>
      <InnerBannerSec menutitle="Services" title="Transfer Pricing Services in Chennai"/>

        <section className='SerinListSec'>
          <Container>
            <div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                <h2>Transfer Pricing</h2> 
                <p>Banusekar provides transfer pricing services in Chennai by supporting the creation and application of workable transfer pricing policies, the harmonization of current ones, the adoption of new transfer pricing structures, and the alignment of prices with corporate organizations.</p>
              </div>
            <div className='SerInListrow'>
              <div className='SerInListcol colone'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/ExcellenceinTransferPricing.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Transfer Pricing Documentation</h4>
                                <p>The Income Tax Act and Rules prescribe 6 methods to determine the Arm’s Length Price. The method that is to be adopted is the most appropriate of these 6 methods. Documentation in relation to transfer pricing plays a key role in establishing whether a transaction made between associated enterprises is at Arm’s Length Price. Serious repercussions would arise including disallowance, penalty and secondary adjustment where the payment made to an associated enterprise is found to be at variance with the Arm’s Length Price. Penalty may also be leviable for failure to maintain transfer pricing documentation.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p>It is imperative to have a thorough study done and to keep appropriate documentation to ensure that a transaction with an associated enterprise can be established to be at Arm’s Length Price.</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol coltwo'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/ExpertHandlingofTransferPricingAudits.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Transfer Pricing Audit</h4>
                                <p>Where the revenue takes up a matter for determining whether a transaction with an associated enterprise is at Arm’s Length, proper representation would be required before the Transfer Pricing Authorities to demonstrate that the transaction is one which at Arm’s Length. This would require a thorough study of the facts, drafting of appropriate written submissions and good knowledge of the law on the subject as evolved on the basis of various decisions of the Tribunal and Courts. This might help to ensure that no transfer pricing adjustment is made and thus mitigate any hardship that could be caused to assessees in this regard.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol colthree'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/CraftingWinning.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Representation in Appellate Fora</h4>
                                <p>Transfer pricing is a complex subject which requires a deep study of the facts, nature of business and the jurisprudence that has evolved on the subject. Presenting appropriate papers and documents to demonstrate that the transaction is at Arm’s Length Price would be compelling both before the Dispute Resolution Panel / Commissioner of Income Tax (Appeals) as also before the Tribunal and higher appellate fora. This can be done only by a thorough study of various aspects and a representation with skill and knowledge.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </Container>
      </section>
	  
      <NewEventSec/>
    
    
    </>
  )
}

export default BlackMoney