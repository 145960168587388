import React from 'react';
import { useEffect } from 'react';
import NewEventSec from '../CommComponent/NewEventSec';
import InnerBannerSec from '../CommComponent/InnerBannerSec';
import { Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";

const PreventionMoney = () => { 
    useEffect(() => {
        document.body.classList.add('ServicesPage')
        return () => {
            document.body.classList.remove('ServicesPage')
        }
    }, [])
  return (
    <>
        <Helmet>
        <title> Best Money Laundering Attorney in Chennai | Best Lawyer For PMLA in Chennai | Banusekar.in</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Banusekar, one of the best lawyer for PMLA is expertise in handling money laundering cases in Chennai and operates corporate law firm in Chennai for premium legal services."/>
		<meta name="keywords" content="Money Laundering Lawyers In Chennai, Lawyers For PMLA in Chennai, Best Rated Lawyers for Money Laundering Case in Chennai, Lawyers For Pmla in Chennai."/>
		<meta name="keywords" content="Money Laundering Lawyers In Chennai, Lawyers For PMLA in Chennai, Best Rated Lawyers for Money Laundering Case in Chennai, Lawyers For Pmla in Chennai."/>

      </Helmet>
      <InnerBannerSec menutitle="Services" title="Money Laundering Attorney in Chennai"/>

        <section className='SerinListSec'>
          <Container>
            <div className='ComHeadTitles  textalcenter mrgnbtm' data-aos="fade-top" data-aos-duration="1000" >
                <h2>Prevention of <br/>Money Laundering</h2> 
                <p>Searching for right lawyer for your money laundering case? Banusekar is the best-rated lawyers for PMLA cases. <br/>The law chamber of Banusekar is the top corporate law firm in Chennai for premium legal services.</p>
              </div>
            <div className='SerInListrow'>
              <div className='SerInListcol colone'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/PML-RepresentationsAuthoritiesCourts.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Representations before Authorities and Courts</h4>
                                <p>Proceedings before the Enforcement Directorate under the Prevention of Money Laundering Act, 2002 often result in freezing, seizing or confiscating assets that are believed to be derived from or used in money laundering activities and sometimes in arrest of persons involved in money laundering. Handling cases under the Prevention of Money Laundering Act, 2002 requires a keen acumen in analyzing the complex financial transactions that are involved in these cases and a deep understanding of the intricacies of procedures before the Enforcement Directorate and also in relation to search, seizure and arrest. </p>

                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p>The Act mandates strict adherence to timelines at each stage of the proceedings. It demands a deep understanding and analysis of the finance and money trail in the specific facts of each case and an expert grasp of the law and procedures to effectively draft responses and represent clients before Authorities, Tribunals and Courts in money laundering cases. </p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol coltwo'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/PML-AdvisoryConsultancyServices.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Advisory / Consultancy Services</h4>
                                <p>Navigating the complexities of financial investigations under the Prevention of Money Laundering Act demands an in-depth understanding of applicable laws and procedures and the ability to identify and leverage potential defenses that may not be immediately evident. Expert legal and consultancy advice is indispensable in laying a solid foundation and steering through the intricacies of these cases.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='SerInListcol colthree'>
                <div className='SerInListitem' data-aos="fade-down" data-aos-duration="1000" >
                    <div className='SerInListinRow'>
                        <div className='SerInListCol SInLHalfCol'>
                            <img src={require("../../assets/images/PML-Opinion.jpg")} alt="Service List Icon"/>
                        </div>
                        <div className='SerInListCol SInLHalfCol'>
                            <div className='SerInListCont'>
                                <h4>Opinion</h4>
                                <p>A comprehensive analysis of the various facets of a case is crucial in determining compliance with legal provisions or identifying potential abuses of the law. Expert opinions on matters under the Prevention of Money Laundering Act offer valuable insights into available legal recourses and the potential effectiveness of pursuing specific remedies.</p>
                            </div>
                        </div>
                        <div className='SerInListCol SInLFullCol'>
                            <div className='SerInListMartop'>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </Container>
      </section>
	  
      <NewEventSec />
    
    
    </>
  )
}

export default PreventionMoney